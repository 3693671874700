interface Props {
  home: boolean;
  onHomeButtonClick(): void;
}

const HomeButton = ({ home, onHomeButtonClick }: Props) => {
  return (
    <div
      id="homeButton"
      className={home ? "shown" : "hidden"}
      onClick={onHomeButtonClick}
    ></div>
  );
};

export default HomeButton;
