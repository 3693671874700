import "../../Styles/Tabs.css";

interface Comp {
  cca: string;
  ccaClass: string;
  argamassa: string;
  argamassaClass: string;
  vedAgua: string;
  vedAguaClass: string;
  isoTerm: string;
  isoTermClass: string;
  isoAcust: string;
  isoAcustClass: string;
  residuos: string;
  residuosClass: string;
  resistIncend: string;
  resistIncendClass: string;
  riscoAcidente: string;
  riscoAcidenteClass: string;
}

interface Props {
  current: boolean;
  lwImg: string;
  lwValue: string;
  vedImg: string;
  vedValue: string;
  compLw: Comp;
  compVed: Comp;
}

const Comparativo = ({
  current,
  lwImg,
  lwValue,
  vedImg,
  vedValue,
  compLw,
  compVed,
}: Props) => {
  return (
    <>
      <div id="custos" className={current ? "tab current" : "tab"}>
        <form>
          <div className="vedInput">
            <select className="lw disabled" disabled value={lwValue} name="lw">
              <option value="sp">Lightwall SP</option>
              <option value="_2p">Lightwall 2P</option>
            </select>
            <select
              className="sistVed disabled"
              disabled
              value={vedValue}
              name="sistVed"
            >
              <option value="tijolo">Bloco Cerâmico</option>
              <option value="bloco">Bloco de Concreto</option>
              <option value="drywall">Drywall</option>
              <option value="steelFrame">Steel Frame</option>
            </select>
          </div>
          <div className="vedInput">
            <img src={lwImg} alt="" />
            <img src={vedImg} alt="" />
          </div>
          <div style={{ color: "transparent" }} className="vedInput obs">
            <ul>
              <li>
                Valores baseados na tabela <strong>SINAPI</strong> da Caixa
                Econômica Federal
              </li>
            </ul>
          </div>
        </form>
      </div>
      <div
        id="contentComparativo"
        className={current ? "content sw" : "content hd"}
      >
        <h3>Uso de Cimento, Cal e Areia:</h3>
        <div className="field">
          <p className={compLw.ccaClass}>{compLw.cca}</p>
          <p className={compVed.ccaClass}>{compVed.cca}</p>
          <p className="economia"></p>
        </div>
        <h3>Uso de argamassa para assentamento:</h3>
        <div className="field">
          <p className={compLw.argamassaClass}>{compLw.argamassa}</p>
          <p className={compVed.argamassaClass}>{compVed.argamassa}</p>
          <p className="economia"></p>
        </div>
        <h3>Estanqueidade:</h3>
        <div className="field">
          <p className={compLw.vedAguaClass}>{compLw.vedAgua}</p>
          <p className={compVed.vedAguaClass}>{compVed.vedAgua}</p>
          <p className="economia"></p>
        </div>
        <h3>Isolamento Térmico:</h3>
        <div className="field">
          <p className={compLw.isoTermClass}>{compLw.isoTerm}</p>
          <p className={compVed.isoTermClass}>{compVed.isoTerm}</p>
          <p className="economia"></p>
        </div>
        <h3>Isolamento Acústico:</h3>
        <div className="field">
          <p className={compLw.isoAcustClass}>{compLw.isoAcust}</p>
          <p className={compVed.isoAcustClass}>{compVed.isoAcust}</p>
          <p className="economia"></p>
        </div>
        <h3>Geração de Resíduos:</h3>
        <div className="field">
          <p className={compLw.residuosClass}>{compLw.residuos}</p>
          <p className={compVed.residuosClass}>{compVed.residuos}</p>
          <p className="economia"></p>
        </div>
        <h3>Resistência a Incêndios:</h3>
        <div className="field">
          <p className={compLw.resistIncendClass}>{compLw.resistIncend}</p>
          <p className={compVed.resistIncendClass}>{compVed.resistIncend}</p>
          <p className="economia"></p>
        </div>
        <h3>Risco de Acidente de Trabalho:</h3>
        <div className="field">
          <p className={compLw.riscoAcidenteClass}>{compLw.riscoAcidente}</p>
          <p className={compVed.riscoAcidenteClass}>{compVed.riscoAcidente}</p>
          <p className="economia"></p>
        </div>
      </div>
    </>
  );
};

export default Comparativo;
