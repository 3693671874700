import logo from "../../Images/logo.png";

interface Props {
  home: boolean;
  onLogoClick(): void;
}

const Logo = ({ home, onLogoClick }: Props) => {
  return (
    <div id="logo" className={home ? "home" : "nohome"} onClick={onLogoClick}>
      <img src={logo} alt="Logo da Lightwall" />
    </div>
  );
};

export default Logo;
