import { useState } from "react";

import "../../Styles/Comparativo.css";

import vedacao from "../../Images/vedacao.png";
import custos from "../../Images/custos.png";
import check from "../../Images/check.png";

import sp from "../../Images/sp.png";
import _2p from "../../Images/2p.png";

import tijolo from "../../Images/tijolo.png";
import bloco from "../../Images/bloco.png";
import drywall from "../../Images/drywall.webp";
import steelFrame from "../../Images/steelFrame.jpg";

import TimePoint from "./TimePoint";
import Dimensoes from "./Dimensoes";
import Custos from "./Custos";
import Comparativo from "./Comparativo";

interface Props {
  show: boolean;
}

interface Costs {
  material: number;
  maoDeObra: number;
  administracao: number;
  tempo: number;
}

interface Comp {
  cca: string;
  ccaClass: string;
  argamassa: string;
  argamassaClass: string;
  vedAgua: string;
  vedAguaClass: string;
  isoTerm: string;
  isoTermClass: string;
  isoAcust: string;
  isoAcustClass: string;
  residuos: string;
  residuosClass: string;
  resistIncend: string;
  resistIncendClass: string;
  riscoAcidente: string;
  riscoAcidenteClass: string;
}

const Calculadora = ({ show }: Props) => {
  const [actives, setActives] = useState<boolean[]>([true, false, false]);

  const [vedEst, setVedEst] = useState<string>("m²");
  const [vedEstValue, setVedEstValue] = useState<number>(0);

  const [calculated, setCalculated] = useState<boolean>(false);
  const [imgPlanta, setImgPlanta] = useState<boolean>(false);
  const [imgPeDir, setImgPeDir] = useState<boolean>(false);

  const [lwImg, setLwImg] = useState<string>(sp);
  const [vedImg, setVedImg] = useState<string>(tijolo);
  const [lwValue, setLwValue] = useState<string>("sp");
  const [vedValue, setVedValue] = useState<string>("tijolo");

  const [custosLw, setCustosLw] = useState<Costs>({
    material: 163,
    maoDeObra: 40,
    administracao: 20,
    tempo: 40,
  });
  const [custosVed, setCustosVed] = useState<Costs>({
    material: 80,
    maoDeObra: 82,
    administracao: 100,
    tempo: 8,
  });

  const compLw: Comp = {
    cca: "Não se aplica",
    ccaClass: "bom",
    argamassa: "Médio",
    argamassaClass: "medio",
    vedAgua: "Alta",
    vedAguaClass: "bom",
    isoTerm: "Alto",
    isoTermClass: "bom",
    isoAcust: "Alto",
    isoAcustClass: "bom",
    residuos: "Baixo",
    residuosClass: "bom",
    resistIncend: "Alta",
    resistIncendClass: "bom",
    riscoAcidente: "Baixo",
    riscoAcidenteClass: "bom",
  };
  const [compVed, setCompVed] = useState<Comp>({
    cca: "Alto",
    ccaClass: "ruim",
    argamassa: "Alto",
    argamassaClass: "ruim",
    vedAgua: "Baixa",
    vedAguaClass: "ruim",
    isoTerm: "Médio",
    isoTermClass: "medio",
    isoAcust: "Médio",
    isoAcustClass: "medio",
    residuos: "Alto",
    residuosClass: "ruim",
    resistIncend: "Média",
    resistIncendClass: "medio",
    riscoAcidente: "Médio",
    riscoAcidenteClass: "medio",
  });

  return (
    <div className={show ? "shown" : "hidden"}>
      <div id="timeLine">
        <TimePoint
          active={actives[0]}
          title="DIMENSÕES"
          icon={vedacao}
          alt="ícone de vedação"
          onTimePointClick={() => handleTimePointClick(0)}
        />
        <TimePoint
          active={actives[1]}
          title="CUSTOS"
          icon={custos}
          alt="ícone de custos"
          onTimePointClick={() => handleTimePointClick(1)}
        />
        <TimePoint
          active={actives[2]}
          title="COMPARATIVO"
          icon={check}
          alt="ícone de check"
          onTimePointClick={() => handleTimePointClick(2)}
        />
      </div>
      <div id="tabs">
        <Dimensoes
          current={actives[0]}
          vedEst={vedEst}
          calculated={calculated}
          imgPlanta={imgPlanta}
          imgPeDir={imgPeDir}
          onPlantaFocus={() => setImgPlanta(true)}
          onPlantaBlur={() => setImgPlanta(false)}
          onPeDirFocus={() => setImgPeDir(true)}
          onPeDirBlur={() => setImgPeDir(false)}
          onNumberInput={() => handleNumberInput()}
        />
        <Custos
          current={actives[1]}
          lwImg={lwImg}
          vedImg={vedImg}
          vedEstValue={vedEstValue}
          custosLw={custosLw}
          custosVed={custosVed}
          onLwChange={() => handleLwChange()}
          onVedChange={() => handleVedChange()}
        />
        <Comparativo
          current={actives[2]}
          lwImg={lwImg}
          vedImg={vedImg}
          lwValue={lwValue}
          vedValue={vedValue}
          compLw={compLw}
          compVed={compVed}
        />
      </div>
    </div>
  );

  function handleTimePointClick(i: number) {
    const nextActives = actives.slice();
    nextActives.fill(false);
    nextActives[i] = true;

    setActives(nextActives);
  }

  function handleNumberInput() {
    const areaConst = document.getElementById("area-const") as HTMLInputElement;
    const peDir = document.getElementById("pe-dir") as HTMLInputElement;

    if (areaConst.value === "" || peDir.value === "") {
      setVedEst("m²");
      setCalculated(false);
      setVedEstValue(0);
      return;
    }

    const areaConstValue = parseFloat(areaConst.value);
    const peDirValue = parseFloat(peDir.value);
    const vedEstValue =
      areaConstValue * (peDirValue * 1.03) - areaConstValue * 0.43;

    setVedEst("" + vedEstValue.toFixed(2));
    setVedEstValue(vedEstValue);
    setCalculated(true);
    setImgPlanta(false);
    setImgPeDir(false);
  }

  function handleLwChange() {
    const lw = document.getElementById("lw") as HTMLInputElement;
    const img = lw.value;
    setLwValue(img);

    if (img === "sp") {
      setLwImg(sp);
      setCustosLw((prevState) => ({
        ...prevState,
        material: 163,
      }));
    } else {
      setLwImg(_2p);
      setCustosLw((prevState) => ({
        ...prevState,
        material: 236,
      }));
    }
  }

  function handleVedChange() {
    const sisVed = document.getElementById("sistVed") as HTMLInputElement;
    const img = sisVed.value;
    setVedValue(img);

    switch (img) {
      case "tijolo":
        setVedImg(tijolo);
        setCustosVed((prevState) => ({
          ...prevState,
          material: 80,
          maoDeObra: 82,
          administracao: 100,
          tempo: 8,
        }));
        setCompVed((prevState) => ({
          ...prevState,
          cca: "Alto",
          ccaClass: "ruim",
          argamassa: "Alto",
          argamassaClass: "ruim",
          vedAgua: "Baixa",
          vedAguaClass: "ruim",
          isoTerm: "Médio",
          isoTermClass: "medio",
          isoAcust: "Médio",
          isoAcustClass: "medio",
          residuos: "Alto",
          residuosClass: "ruim",
          resistIncend: "Média",
          resistIncendClass: "medio",
          riscoAcidente: "Médio",
          riscoAcidenteClass: "medio",
        }));
        break;
      case "bloco":
        setVedImg(bloco);
        setCustosVed((prevState) => ({
          ...prevState,
          material: 100,
          maoDeObra: 65,
          administracao: 80,
          tempo: 10,
        }));
        setCompVed((prevState) => ({
          ...prevState,
          cca: "Alto",
          ccaClass: "ruim",
          argamassa: "Alto",
          argamassaClass: "ruim",
          vedAgua: "Baixa",
          vedAguaClass: "ruim",
          isoTerm: "Baixo",
          isoTermClass: "ruim",
          isoAcust: "Médio",
          isoAcustClass: "medio",
          residuos: "Alto",
          residuosClass: "ruim",
          resistIncend: "Média",
          resistIncendClass: "medio",
          riscoAcidente: "Médio",
          riscoAcidenteClass: "medio",
        }));
        break;
      case "drywall":
        setVedImg(drywall);
        setCustosVed((prevState) => ({
          ...prevState,
          material: 120,
          maoDeObra: 60,
          administracao: 22.86,
          tempo: 35,
        }));
        setCompVed((prevState) => ({
          ...prevState,
          cca: "Não se aplica",
          ccaClass: "bom",
          argamassa: "Não se aplica",
          argamassaClass: "bom",
          vedAgua: "Baixa",
          vedAguaClass: "ruim",
          isoTerm: "Médio",
          isoTermClass: "medio",
          isoAcust: "Baixo",
          isoAcustClass: "ruim",
          residuos: "Médio",
          residuosClass: "medio",
          resistIncend: "Baixa",
          resistIncendClass: "ruim",
          riscoAcidente: "Médio",
          riscoAcidenteClass: "medio",
        }));
        break;
      case "steelFrame":
        setVedImg(steelFrame);
        setCustosVed((prevState) => ({
          ...prevState,
          material: 200,
          maoDeObra: 100,
          administracao: 26.67,
          tempo: 30,
        }));
        setCompVed((prevState) => ({
          ...prevState,
          cca: "Não se aplica",
          ccaClass: "bom",
          argamassa: "Não se aplica",
          argamassaClass: "bom",
          vedAgua: "Média",
          vedAguaClass: "medio",
          isoTerm: "Média",
          isoTermClass: "medio",
          isoAcust: "Média",
          isoAcustClass: "medio",
          residuos: "Médio",
          residuosClass: "medio",
          resistIncend: "Média",
          resistIncendClass: "medio",
          riscoAcidente: "Médio",
          riscoAcidenteClass: "medio",
        }));
    }
  }
};

export default Calculadora;
