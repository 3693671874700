interface Props {
  title: string;
  onMainButtonClick(): void;
}

const MainButton = ({ title, onMainButtonClick }: Props) => {
  return (
    <button
      type="button"
      className="mainButton"
      onClick={onMainButtonClick}
    >
      {title}
    </button>
  );
};

export default MainButton;
