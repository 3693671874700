interface Props {
  active: boolean;
  title: string;
  icon: string;
  alt: string;
  onTimePointClick(): void;
}

const TimePoint = ({ active, title, icon, alt, onTimePointClick }: Props) => {
  return (
    <h2
      className={active ? "timePoint active" : "timePoint"}
      onClick={onTimePointClick}
    >
      <img src={icon} alt={alt} /> {title}
    </h2>
  );
};

export default TimePoint;
