import envelope from "../../Images/envelope.png";

interface Props {
  home: boolean;
  onEnvelopeClick(): void;
}

const Envelope = ({ home, onEnvelopeClick }: Props) => {
  return (
    <div
      id="envelope"
      className={home ? "home" : "nohome"}
      onClick={onEnvelopeClick}
    >
      <img src={envelope} alt="" />
    </div>
  );
};

export default Envelope;
