import MainButton from "./MainButton";

interface Props {
  show: boolean;
  handleComparativoClick(): void;
  handlePortfolioClick(): void;
}

const MainButtons = ({
  show,
  handleComparativoClick,
  handlePortfolioClick,
}: Props) => {
  return (
    <div id="mainButtons" className={show ? "shown" : "hidden"}>
      <MainButton
        title="COMPARATIVO"
        onMainButtonClick={handleComparativoClick}
      />
      <MainButton title="PORTFÓLIO" onMainButtonClick={handlePortfolioClick} />
    </div>
  );
};

export default MainButtons;
