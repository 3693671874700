import { TouchEvent, useState } from "react";

import "../../Styles/Portfolio.css";

interface Props {
  show: boolean;
}

const Portfolio = ({ show }: Props) => {
  const [impar, setImpar] = useState<number>(1);
  const [par, setPar] = useState<number>(2);
  const [posicao, setPosicao] = useState<number>(1);
  const [current, setCurrent] = useState<string>("impar");

  const imgImpar = "./Workshop/" + impar + ".jpg";
  const imgPar = "./Workshop/" + par + ".jpg";
  const imgPos = "./Workshop/" + posicao + ".jpg";

  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const minSwipeDistance = 50;

  return (
    <div
      id="portfolio"
      className={show ? "shown" : "hidden"}
      onTouchStart={(e) => onTouchStart(e)}
      onTouchMove={(e) => onTouchMove(e)}
      onTouchEnd={(e) => onTouchEnd(e)}
    >
      <span className="control left" onClick={() => handleLeftClick()}>
        &lt;
      </span>
      <img
        src={imgImpar}
        alt="slide"
        id="impar"
        className={current === "impar" ? "slide current" : "slide"}
      />
      <img
        src={imgPar}
        alt="slide"
        id="par"
        className={current === "par" ? "slide current" : "slide"}
      />
      <span className="control right" onClick={() => handleRightClick()}>
        &gt;
      </span>
    </div>
  );

  function onTouchStart(e: TouchEvent) {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  }
  function onTouchMove(e: TouchEvent) {
    setTouchEnd(e.targetTouches[0].clientX);
  }
  function onTouchEnd(e: TouchEvent) {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      handleRightClick();
    } else if (isRightSwipe) {
      handleLeftClick();
    }
  }

  function handleLeftClick() {
    if (current === "impar") {
      if (impar === 1) {
        setImpar(59);
        return;
      } else {
        setPar(impar - 1);
      }
      setCurrent("par");
    } else {
      setImpar(par - 1);
      setCurrent("impar");
    }
  }
  function handleRightClick() {
    if (current === "impar" && impar === 59) {
      setImpar(1);
      return;
    }
    if (current === "par") {
      setImpar(par + 1);
      setCurrent("impar");
    } else {
      setPar(impar + 1);
      setCurrent("par");
    }
  }
};

export default Portfolio;
