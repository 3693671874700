import "../../Styles/Tabs.css";
import "../../Styles/Contents.css";

import planta from "../../Images/planta.png";
import pedir from "../../Images/pedir.png";
import vedest from "../../Images/vedest.png";

interface Props {
  current: boolean;
  vedEst: string;
  calculated: boolean;
  imgPlanta: boolean;
  imgPeDir: boolean;
  onPlantaFocus(): void;
  onPlantaBlur(): void;
  onPeDirFocus(): void;
  onPeDirBlur(): void;
  onNumberInput(): void;
}

const Dimensoes = ({
  current,
  vedEst,
  calculated,
  imgPlanta,
  imgPeDir,
  onPlantaFocus,
  onPlantaBlur,
  onPeDirFocus,
  onPeDirBlur,
  onNumberInput,
}: Props) => {
  return (
    <>
      <div id="dimensoes" className={current ? "tab current" : "tab"}>
        <form>
          <div className="vedInput">
            <label htmlFor="area-const">
              Qual a área construída de sua obra?
            </label>
            <input
              autoFocus
              type="number"
              step="50"
              min="50"
              name="area-const"
              id="area-const"
              placeholder="m²"
              onFocus={onPlantaFocus}
              onBlur={onPlantaBlur}
              onChange={onNumberInput}
            />
          </div>
          <div className="vedInput">
            <label htmlFor="pe-dir">Qual o pé-direito médio?</label>
            <input
              type="number"
              step="0.5"
              min="2.5"
              max="10"
              name="pe-dir"
              id="pe-dir"
              placeholder="m"
              onFocus={onPeDirFocus}
              onBlur={onPeDirBlur}
              onChange={onNumberInput}
            />
          </div>
          <div className={calculated ? "vedInput calculated" : "vedInput"}>
            <label htmlFor="ved-est">Área de vedação estimada:</label>
            <input
              disabled
              placeholder={vedEst}
              type="number"
              name="ved-est"
              id="ved-est"
            />
          </div>
        </form>
      </div>
      <div id="contentDim" className={current ? "content sw" : "content hd"}>
        <img
          className={
            imgPlanta ? "dimIcons planta calculated" : "dimIcons planta"
          }
          src={planta}
          alt="Planta com marção de larugra x altura"
        />
        <img
          className={imgPeDir ? "dimIcons pedir calculated" : "dimIcons pedir"}
          src={pedir}
          alt="Corte com marcação de altura do pé-direito"
        />
        <img
          className={
            calculated ? "dimIcons vedest calculated" : "dimIcons vedest"
          }
          src={vedest}
          alt="Corte com hachura na área de vedação"
        />
      </div>
    </>
  );
};

export default Dimensoes;
