import "../../Styles/Tabs.css";
import "../../Styles/Contents.css";
import { useState } from "react";

interface Costs {
  material: number;
  maoDeObra: number;
  administracao: number;
  tempo: number;
}
interface Props {
  current: boolean;
  lwImg: string;
  vedImg: string;
  vedEstValue: number;
  custosLw: Costs;
  custosVed: Costs;
  onLwChange(): void;
  onVedChange(): void;
}

const Custos = ({
  current,
  lwImg,
  vedImg,
  vedEstValue,
  custosLw,
  custosVed,
  onLwChange,
  onVedChange,
}: Props) => {
  const totalCustosLw =
    custosLw.material + custosLw.maoDeObra + custosLw.administracao;

  const totalCustosVed =
    custosVed.material + custosVed.maoDeObra + custosVed.administracao;

  const totalLw = vedEstValue * totalCustosLw;
  const materialLw = vedEstValue * custosLw.material;
  const maoDeObraLw = vedEstValue * custosLw.maoDeObra;
  const administracaoLw = vedEstValue * custosLw.administracao;
  const tempoLw = vedEstValue / custosLw.tempo;

  const totalVed = vedEstValue * totalCustosVed;
  const materialVed = vedEstValue * custosVed.material;
  const maoDeObraVed = vedEstValue * custosVed.maoDeObra;
  const administracaoVed = vedEstValue * custosVed.administracao;
  const tempoVed = vedEstValue / custosVed.tempo;

  const [detalhar, setDetalhar] = useState<boolean>(false);

  return (
    <>
      <div id="custos" className={current ? "tab current" : "tab"}>
        <form>
          <div className="vedInput">
            <label htmlFor="lw">
              Escolha os sistemas a comparar:
              <br />
            </label>
            <select className="lw" name="lw" id="lw" onChange={onLwChange}>
              <option value="sp">Lightwall SP</option>
              <option value="_2p">Lightwall 2P</option>
            </select>
            <select
              className="sistVed"
              name="sistVed"
              id="sistVed"
              onChange={onVedChange}
            >
              <option value="tijolo">Bloco Cerâmico</option>
              <option value="bloco">Bloco de Concreto</option>
              <option value="drywall">Drywall</option>
              <option value="steelFrame">Steel Frame</option>
            </select>
          </div>
          <div className="vedInput">
            <img src={lwImg} alt="" />
            <img src={vedImg} alt="" />
          </div>
          <div className="vedInput obs">
            <h3>Observações:</h3>
            <ul>
              <li>
                Os valores apresentados são <strong>ESTIMATIVAS</strong>{" "}
                baseadas nas dimensões informadas. Para definição assertiva do
                valor é necessário realizar um orçamento junto à nossa equipe
                comercial.
              </li>
            </ul>
          </div>
        </form>
      </div>
      <div id="contentCustos" className={current ? "content sw" : "content hd"}>
        <h2>Custo total do sistema:</h2>
        <div className="field destaque">
          <p className="lw">
            {totalLw.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </p>
          <p className="sistVed">
            {totalVed.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </p>
          {economia(totalVed, totalLw)}
        </div>

        <h2>Tempo estimado de execução:</h2>
        <div className="field destaque">
          <p className="lw">{Math.ceil(tempoLw)} dias</p>
          <p className="sistVed">{Math.ceil(tempoVed)} dias</p>
          <p className="economia">
            {tempoLw > 0 && tempoVed / tempoLw > 0
              ? (tempoVed / tempoLw).toFixed(2) + "x mais Rápido"
              : ""}
          </p>
        </div>

        <button
          type="button"
          id="detalhar"
          className={detalhar ? "detalhado" : ""}
          onClick={() => handleDetalharClick()}
        >
          Detalhar
        </button>

        <div id="detalhe" className={detalhar ? "visible" : "hidden"}>
          <h3>Custo do material:</h3>
          <div className="field">
            <p className="lw">
              {materialLw.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
            <p className="sistVed">
              {materialVed.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
            {economia(materialVed, materialLw)}
          </div>
          <h3>Custo de mão-de-obra:</h3>
          <div className="field">
            <p className="lw">
              {maoDeObraLw.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
            <p className="sistVed">
              {maoDeObraVed.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
            {economia(maoDeObraVed, maoDeObraLw)}
          </div>
          <h3>Custos indiretos (Adm, Entulhos, etc.):</h3>
          <div className="field">
            <p className="lw">
              {administracaoLw.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
            <p className="sistVed">
              {administracaoVed.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
            {economia(administracaoVed, administracaoLw)}
          </div>
        </div>
      </div>
    </>
  );

  function reducao(x: number, y: number) {
    return ((x - y) / x) * 100;
  }
  function economia(x: number, y: number) {
    if (reducao(x, y) > 0) {
      return (
        <p className="economia">{reducao(x, y).toFixed(2) + "% de economia"}</p>
      );
    } else if (reducao(x, y) < 0) {
      return (
        <p className="caro">{(reducao(x, y) * -1).toFixed(2) + "% a mais"}</p>
      );
    }
    return <p></p>;
  }

  function handleDetalharClick() {
    detalhar ? setDetalhar(false) : setDetalhar(true);
  }
};

export default Custos;
