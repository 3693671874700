import { useState } from "react";

import "./App.css";

import Envelope from "./Components/Home/Envelope";
import Logo from "./Components/Home/Logo";
import HomeButton from "./Components/Home/HomeButton";
import MainButtons from "./Components/Home/MainButtons";
import Calculadora from "./Components/Comparativo/Calculadora";
import Portfolio from "./Components/Portfolio/Portfolio";

function App() {
  const [states, setStates] = useState<boolean[]>([true, false, false, false]);

  return (
    <section className="App">
      <article className="App-header">
        <Envelope home={states[0]} onEnvelopeClick={() => handleHomeClick()} />
        <Logo home={states[0]} onLogoClick={() => handleHomeClick()} />
        <HomeButton
          home={states[0]}
          onHomeButtonClick={() => handleHomeClick()}
        />
        <MainButtons
          show={states[1]}
          handleComparativoClick={() => handleComparativoClick()}
          handlePortfolioClick={() => handlePortfolioClick()}
        />
        <Calculadora show={states[2]} />
        <Portfolio show={states[3]} />
      </article>
    </section>
  );

  function handleHomeClick() {
    states[0]
      ? setStates([false, true, false, false])
      : setStates([true, false, false, false]);
  }
  function handleComparativoClick() {
    const nextStates = states.slice();
    nextStates[1] = false;
    nextStates[2] = true;

    setStates(nextStates);
  }
  function handlePortfolioClick() {
    const nextStates = states.slice();
    nextStates[1] = false;
    nextStates[3] = true;

    setStates(nextStates);
  }
}

export default App;
